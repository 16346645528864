@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

:root {
  --color-btn-pop-up: #ffa5c9;
  --ancho-sidebar: 72px;
  --margen-contenedor: 64px;
  --color-textos: #455e6a;
  --color-peligro: #f66f6b;
  --color-success: #6cc09a;
  --color-principal: #df1683;
  --color-secundario: #fcf6f2;
  --color-principal-trasparente: rgba(223, 22, 131, 0.15);
  --color-secundario-trasparente: rgba(78, 51, 137, 0.15);
  --color-principal-trasparente-hover: rgba(223, 22, 131, 0.3);
  --color-secundario-trasparente-hover: rgba(78, 51, 137, 0.3);
  --color-error: #df1626;
  --color-correcto: #16dfcc;
  --color-warning: #dfbe16;

  --color-carga-alta: #e34a4f;
  --color-carga-media: #f19555;
  --color-carga-buena: #6cb275;

  --color-texto-color: #1f586b;
  --color-btn-2: #8998cd;

  /* Degradados */
  --degradado-vertial: linear-gradient(
    360deg,
    rgba(223, 22, 131, 1) 0%,
    rgba(78, 51, 137, 1) 50%
  );
  --degradado-horizontal: linear-gradient(
    90deg,
    rgba(223, 22, 131, 1) 0%,
    rgba(78, 51, 137, 1) 50%
  );
  --degradado-vertial-reverse: linear-gradient(
    360deg,
    rgba(78, 51, 137, 1) 0%,
    rgba(223, 22, 131, 1) 50%
  );
  --degradado-horizontal-reverse: linear-gradient(
    90deg,
    rgba(78, 51, 137, 1) 0%,
    rgba(223, 22, 131, 1) 50%
  );
  /* Grisaceos */
  --color-negro: #000;
  --color-gris0: #49494c;
  --color-gris1: #808080;
  --color-gris2: #b8b8b8;
  --color-gris3: #dddddd;
  --color-gris4: #ebebeb;
  --color-gris5: #f1f1f1;
  --color-gris6: #f9f9f9;
  --color-blanco: #fff;
  --color-fondo: #fff;
  /* Tonalidades */
  --color-tonalidad-principal1: #fd454d;
  --color-tonalidad-principal2: #71f0ca;
  --color-tonalidad-principal3: #e9eef4;
  --color-tonalidad-principal4: #e5f6f1;
  --color-tonalidad-principal5: #85f1ce;
  --color-tonalidad-principal6: #269171;
  --color-tonalidad-principal7: #1dcb98;
  --color-tonalidad-principal8: #B7EDDD;
  --color-tonalidad-principal9: #f6a2d0;
  --color-tonalidad-principal10: #e1edf7;
  --color-tonalidad-principal11: #effcf9;
  /* Shadow */
  --shadow-small: 3px 3px 6px rgba(0, 0, 0, 0.3);
  --shadow-big: 6px 6px 12px rgba(0, 0, 0, 0.16);
  /* Border radius */
  --border-radius-mini: 4px;
  --border-radius-small: 8px;
  --border-radius-big: 12px;
  /* Disabled opacity */
  --opacity-desactivado: 0.4;
  /* Leftbar */
  --leftbar-width: 300px;
  /* Header */
  --header-height: 72px;
  /* Header Inferior */
  --header-inferior-height: 85px;
  /* Logo */
  --logo-height: 32px;
  /* Container general */
  --separacion-lateral-global: 2em;
  /* Cursor */
  --edit-cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAARdJREFUOE+t1EFugkAUBuD/Ydi7wbVzgtoTVI/gCYwnaLpuaqik+x7BG/QI1htwA7ouG7sm09fM0yFOmQmIsGFB+PK/nzcQel5F8jYD6Q8wfaHSS3VMj4aiPp5g0HsQjeV9Ro5KLwzaE8zeQXh0wpzRq8BinEoik6RItp8genDQX37qDAoWj/YCVHoh9zgy6J1MzfghRPNOYI0RZpedWZRBU4Op8jlvBRuYnfHc2QmNpwZr/cpBrEb5oMrN/LLHYMJWTIqL7m0yi3rBbhivVbnZ/d/jBngL1ujwVswBh8BcMNnmdkm955v9nXk7LJJsB8Iq+KPoiEnCIbETOMl4iGT1HgbBK8Z0TooX7ImFRn5V3y9psIaWB39dN5gii+YqVgAAAABJRU5ErkJggg==")
      0 20,
    auto;
  --separacion: 17px;
}
@font-face {
  font-family: "Satoshi";
  font-weight: 100;
  font-style: normal;
  src: url(assets/font/Satoshi-Light.otf);
}
@font-face {
  font-family: "Satoshi";
  font-weight: 400;
  font-style: normal;
  src: url(assets/font/Satoshi-Regular.otf);
}
@font-face {
  font-family: "Satoshi";
  font-weight: 500;
  font-style: normal;
  src: url(assets/font/Satoshi-Medium.otf);
}
@font-face {
  font-family: "Satoshi";
  font-weight: 700;
  font-style: normal;
  src: url(assets/font/Satoshi-Bold.otf);
}
@font-face {
  font-family: "Satoshi";
  font-weight: 900;
  font-style: normal;
  src: url(assets/font/Satoshi-Black.otf);
}
@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 100;
  src: url(assets/font/Satoshi-LightItalic.otf);
}
@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 400;
  src: url(assets/font/Satoshi-Italic.otf);
}
@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 500;
  src: url(assets/font/Satoshi-MediumItalic.otf);
}
@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 700;
  src: url(assets/font/Satoshi-BoldItalic.otf);
}
@font-face {
  font-family: "Satoshi";
  font-style: italic;
  font-weight: 900;
  src: url(assets/font/Satoshi-BlackItalic.otf);
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Satoshi";
}
* {
  font-family: "Satoshi";
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.noData {
  height: 3em;
  font-weight: 600;
  font-size: 24px;
  color: #2d3835;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navegacionGeneralContainer {
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.sideBarGeneralContainer {
  width: var(--ancho-sidebar);
  height: 100%;
}
.switch-button .switch-button__label {
  background-color: white;
  width: 4rem;
  height: 1.5rem;
  border-radius: 3rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 2px solid #f8b997;
  display: flex;
  align-items: center;
}
.switch-button .switch-button__label:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  background-color: #f5c29f;
  content: "";
  border-radius: 50%;
  margin-left: 2px;
  margin-bottom: 0.8px;
  margin-right: 2px;
}
.switch-button .switch-button__checkbox {
  display: none;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
  background-color: rgba(252, 146, 98, 0.1);
  border: none !important;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
  transform: translateX(2.5rem);
}
.dataGeneralContainer {
  width: calc(100% - var(--ancho-sidebar));
  height: calc(100% - 72px - 35px);
  top: 72px;
  position: relative;
  padding-top: 35px;
}
.dataGeneralContainer > *:last-child {
  padding-bottom: 25px;
}
.especialNavegation .navegacionContainer {
  padding: 4px !important;
}
.especialNavegation .navegacionContainer > span {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-header-bar {
  width: calc( 100% - var(--ancho-sidebar));
  height: 72px;
  display: flex;
  background: var(--color-tonalidad-principal11);
  border-bottom: solid 1px var(--color-gris3);
  margin-bottom: 24px;
  position: fixed;
  z-index: 999;
  right: 0;
  left: calc(var(--ancho-sidebar) + 1px);
  top: 0;
}
.visible svg {
  display: inherit;
  opacity: 1;
  transition: all 0.3s;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #f5c29f !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(252, 146, 98, 0.1);
}
.popupSelector .ng-select-container {
  min-height: 55px !important;
}
.ng-select-container {
  border-radius: 8px !important;
  font-weight: 300;
  padding: 0px 17px !important;
  padding-top: 0px !important;
  padding-right: 17px !important;
  padding-bottom: 0px !important;
  padding-left: 17px !important;
  cursor: pointer !important;
  outline: none !important;
  height: 40px !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: inherit !important;
  padding-left: 1em !important;
}

.ng-select .ng-select-container .ng-value-container {
  padding-left: 0 !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  padding-bottom: 0 !important;
  top: inherit !important;
}

.ng-dropdown-panel.ng-select-bottom {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.ng-select-opened .ng-select-container {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-texto-color);
}

.ng-select .ng-select-container .ng-value-container .ng-input > input,
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input
  > input {
  font-size: 16px;
  font-weight: 300;
  color: var(--color-texto-color);
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 16px;
  font-weight: 300;
  color: var(--color-tonalidad-principal2);
}

.ng-dropdown-panel .scroll-host {
  border-radius: 8px !important;
}

.selector .ng-select-container {
  height: 2.3em !important;
}

.selector.carga .ng-select.ng-select-single .ng-select-container {
  width: 16em;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 16px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 15px;
}

.cdk-overlay-dark-backdrop {
  background-color: rgba(137, 152, 205, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.cdk-global-overlay-wrapper:has(.addIntegrantePopup) {
  justify-content: flex-end !important;
}

.fullSizePopup {
  width: 100%;
  height: 100%;
  max-width: none !important;
}

.switch-button {
  z-index: 99 !important;
}

.formSecondBlock-one .ng-select-container,
.formThirdBlock-two .ng-select-container {
  height: 66px !important;
}

.formSecondBlock-one .ng-placeholder,
.formThirdBlock-two .ng-placeholder {
  font-size: 24px !important;
  font-weight: 300;
  line-height: 28px !important;
  font-style: italic !important;
  color: var(--color-texto-color) !important;
}

.formSecondBlock-one .ng-value,
.formThirdBlock-two .ng-value {
  font-size: 24px !important;
  font-weight: 300;
  line-height: 28px !important;
  color: var(--color-texto-color) !important;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: inherit !important;
}
/*NOTIFICACIONES*/
.notificacionesContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
}

.notificacion {
  width: auto;
  display: flex;
  background: white;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.2);
  border-radius: 26px;
  margin: 0px 32px 15px 0em;
  min-height: 50px;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.5s, transform 0.2s;
  transform: translateX(150%);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}
.notificacion.notificacion_containText {
  align-items: flex-start;
}

.notificacion .notificacion_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff7e4;
  position: relative;
}
.notificacion .notificacion_icon:after {
  content: "";
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.notificacionFeedback .notificacion_icon {
  background: #e7f9f0;
}
.notificacionFeedback .notificacion_icon:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAABOFBMVEUAAADj/+Nx1ap516GA2abm+vB616J22J102Jt32Jt52Zx525/l+u7n+fC5685b0Ihd0Ipa0Ihb0Ilcz4hYzoVZz4dZz4hZzoZ/2aJFyXhGyXlHyXk8xnE9x3I9yHIswmbn+fAkv18lv2Amv2AnwGEnwGIowGIqwGMrwWQswWQswmQuwmYww2cxw2kzxGs1xGw7xnA+x3JCyHZHyXhJyntMy31NzH5QzIBRzYBYzoZaz4hd0Iph0Yxm05Bp1JNx1phy15l42J592aGF3KiH3KiJ3aqL3qyS4LGT4bKa4reg5Lui5Lyq58Ot6MSz6sq168u5686+7dLA7dPJ8NnK8NrO8t3Q8t/T8+HU9OLW9OPZ9OXa9Obb9efb9ejd9une9unf9urg9+vi9+zk+O3k+O7m+e/n+fD///+95Av8AAAAIXRSTlMACRITFDJaW1xcXV2UndHS09TU1dra2tzr8/Pz+fn5/v7dDaVRAAAAAWJLR0RnW9PpswAAASVJREFUKM9109VSQ0EQhOGGBHcNEmSChODu7u6e4PC//yNwQcLZwNm+2/qqtrZ2eiRJFQ2dKctLqqO+WD+JxhIWkkRzRJKi7Rae/uoCSTGP2jZVUkXCo7MfdBepwaOD10ClOj28D9CiVLgufAGHXQrX4VvgbsQ8fAR8LZqHlwH2zMNjD8DVgI9PgI9Z8/A6wJb9cu/22UagE8/ARV/Aa9mHmJlZzznwNmkB7+D4JkDuMpmZTWQCn3oHThMu23Q65/2XwNO45XHguwCr9odzPv8JHNs/tpkMwCtwP+pwKt+BpUCTTh2yfuD8X1y1wWE6DdwMOVyjcqeKM4+8zLlVL5Wa3EmujLuzaZQUafMVvTUiSZGm8CVqLMxuWVl9PJlvyXhdiSR9A5qClU5Vv0EFAAAAAElFTkSuQmCC');
}
.notificacionInfo .notificacion_icon {
  background: #f2f3ff;
}
.notificacionInfo .notificacion_icon:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAllBMVEUAAABAYN85VeMzTeYvTuozUusyUOsxTusxUOwwT+wyUOwxUOwxT+wxUesxUOwwUewxUOwxUOwxUOwxT+wxT+wyUOwxUOwxUOwxUOwxUOwxUOwxUOwzUuw6V+06WO07We1OaO9Pae9Ubu9Vbu9jevGElvSVpfaXp/bHz/vL0fvM0vvN0/vX3PzY3fzn6v7v8P/y8//////HTXc+AAAAG3RSTlMACAkKMTIzNJKUlZaXm52eoMXGx9fY2fT1/f5gbCkOAAAAAWJLR0Qx2dsdcgAAALlJREFUKM/lk8kOgkAQBcsR3HdRkHEXcEPp//86D3po1CHxbN3eq6STTroBaPbmkS0Rzbo+T+qj2H4hHhqA+tQ6mBhgZJ0MoBm7dezT03mTpmud28xVWl5EzktVBOiNdiIiW1WE6Fnru8h9pZuStsnplFi3/uAnfSyKrELnInmFvonc/kSHVTosnYO9ilxt6Rw6Oh6ybK9zi0bFKS48GLp1HzATlx0bADP4On/Rr72+zO8E7y8YtD2ABwf7XbCoSq65AAAAAElFTkSuQmCC');
}
.notificacionWarning .notificacion_icon {
  background: #fff7e4;
}
.notificacionWarning .notificacion_icon:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAeCAYAAACiyHcXAAAABmJLR0QA/wD/AP+gvaeTAAACeklEQVRIicWWTUgVURiGn2+uXsRUssKgsAgJE43UKIMgimgR1cZ1CSoVFbooISJKC6mISAmCoHKwbCO0qCRolYsI2hRUC1u0MAqjTQtNMr3ztrga1590zjjUuxnO33MevjN/RsSohwJ+0RxAhQfvEDeskeEoLIsk4JMj8RKoyuh+bcZ2q+enK8+LIoFomSEAUI04FQXnXAl1s1opBoC8OYZHLYsyq+OTC9O5EsEEl/8iAJAbjNPuynSqhHy2Td4L862TiR3WyIuw3NCVkDCJzmkCiSQU1YCXnTnVZHSqLTw7/HH41AE103arasV2P8Sqzs+cvZliDsYqoZvkCS7NGlhSnL7mrZ29xriqHgpikwhyOAOsCjM3IyuDMU7HIqHbrDPjpKMAAGa0yGf94iUSXANyokgASYkri5LQXXYBtREFplIrnz2RJNRLQkbHIgXSLNGhNrKcJRjhCLApDgmgnDUcdpLQAwoFF2MSSDOhXd0sDy0RjNEKrIhTAlgWpDgXSkI+GwyOxywAgMEJ3aFiYQlxHcie2T9X9LUfggk09DysR5aMzjnkMqBd7Bc8CUtMEzxQ4LYEDlgDfbMk1EtSI7wFSp2I0fLRRii3ZsYg8ziGaXISSCSx6jZs7zOsunXm53yhlJBH01TDAHSPIk3wAVgaGlN2DKs8+6epN+0wcMtFZNjGKbWjDHkAk79k4QUAKyiZtx0i+UF2+l3kyafSjAZXggYfg1KTjRQafOSKwKBBPlss8OlD7HMmABRuhKKt8O0VfH8fCQE8taCLH0BuVEIMGfWAL/9RAMRnz6AVcHvbxKhgHhfSj6jPzkAcMsj/Z7vDsGfct3r6fwMTY7uaBrQ8qAAAAABJRU5ErkJggg==');
}
.notificacionError .notificacion_icon {
  background: #fff2f3;
}
.notificacionError .notificacion_icon:after {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAA3lBMVEUAAAD/QED/Zmb/VVX/VVX/TU3/Rkb/VVXrTk74TEz4UVHyTk7zUVHzTU3zUUv2T072T0/2Tk70UE70T030UE71T0/1UE71T071T0/1T071UE71T072T0/2UE72T072T030T071T071Tk71T0/1T071T072T071T072T071T071T071T071T071T071T071T071UlH1VlX2V1b2WFf2Wln3aGf3amn3a2v3bm33c3L3dHP3dXX3dnX3d3b3d3f5j475kZD6mpr9ysv9y8z91NX91tf92Nn+6On/8vP////is5/EAAAAL3RSTlMABAUGCQoLDA0lJicpKyyKi4yNjpCSk5SVuLq7vL2+v8LE0NPU1djv8vP09vv8/THCP8oAAAABYktHREmHBeR8AAABD0lEQVQ4y4XU2VLCQBAF0BtDVFxA4xLFhRFlUQKNivuKovT/f5EvKWump5Ppx1unMktqLuBMI+sNh72sgfKJszEREdH4IC4zyyf0P+0V3SSnZM3ZatjoShpN+cZXmpEqsc71+aEr2zwyP2jKNvTK/Kys6BiJCrV0TFWI2jGAPapGtA+s5yE02pQfUhDtohtGXVyF0SUGYTTAhUhemJ9EdI4dkdz+/tyIKMVaToEZbQBpCKUAokMnevv6fneCVgQAtSMrul4wL+xNmeLZ2OqOmfneN46azJnnE8U4ajqbTVUj9lVidGW8p+4ro9SBVEatDFeZklqpWXffSsoKKtoq/na+HVV0Xb3Z6fc7zbqb/gHfJKCWPsJsOwAAAABJRU5ErkJggg==');
}

.notificacion .notificacion_cerrar {
  display: block;
  pointer-events: auto;
  margin-top: 4px;
  margin-right: 5px;
}

.notificacion.notificacion_containText .notificacion_cerrar{
  margin-top: 12px;
}

.notificacion .notificacion_cerrar:after {
  font-family: "Material Icons";
  font-size: 24px;
  content: "close";
  color: var(--color-gris2);
  cursor: pointer;
}

.notificacion .notificacion_cerrar:hover:after {
  color: var(--color-negro);
}

.notificacion_body {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 5px;
}
.notificacion.notificacion_containText .notificacion_body{
  margin-top: 5px;
}
.notificacion_animationOpen {
  transform: translateX(0);
}

.notificacion_animationRemove {
  opacity: 0;
}

body > div > div > div.notificacion_body > h4 {
  font-weight: 500;
  margin: 0;
  font-size: 12pt;
}
body > div > div > div.notificacion_body > p {
  margin: 0;
}
.notificacionError .notificacion_body p {
  font-weight: 500;
  color: #e41211;
}
.notificacionInfo .notificacion_body p {
  font-weight: 500;
  color: #3150ec;
}
.notificacionFeedback .notificacion_body p {
  font-weight: 500;
  color: #24bf5f;
}
.notificacionWarning .notificacion_body p {
  font-weight: 500;
  color: #fe9e00;
}

.notificacion.notificacion_containText .notificacion_body p {
  margin-block-end: 10px;
  max-width: 340px;
}
/*FIN NOTIFICACIONES*/

/*ICONO CARGA*/
.iconoCargar {
  animation: cargarAnimacion 1s ease-in-out infinite;
  display: inline-block;
  position: relative;
  background: radial-gradient(
    circle,
    rgba(146, 83, 188, 0) 56%,
    var(--color-principal) 57%,
    var(--color-principal) 67%,
    rgba(176, 79, 165, 0) 68%
  );
  clip-path: inset(50% 50% 0px 0px);
  transform: rotate(180deg);
}

.iconoCargar.blanco,
.iconoCargar.white {
  background: radial-gradient(
    circle,
    rgba(146, 83, 188, 0) 57%,
    var(--color-blanco) 57%,
    var(--color-blanco) 67%,
    rgba(176, 79, 165, 0) 67%
  );
}

@keyframes cargarAnimacion {
  0% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.cdk-overlay-pane.loadingPopup {
  min-width: 140px;
  min-height: 140px;
  text-align: center;
}

.cdk-overlay-pane.loadingPopup .mat-dialog-container {
  background: transparent;
  box-shadow: none;
}

.cdk-overlay-pane {
  max-width: 90vw !important;
}

.cdk-overlay-container {
  z-index: 2000;
}

body.loaderActive > .cdk-overlay-container {
  z-index: 5000000;
}

/*FIN ICONO CARGA*/
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 11px;
  }
}
/*graficos mapacalor*/
div#dottedchart tspan,
div#dottedchart title,
div#chart tspan,
div#chart title,
div#chart2 tspan,
div#chart2 title {
  font-weight: 600;
  fill: var(--color-texto-color);
}

app-modal-image {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  width: 1000px;
  height: 440px;
  animation-name: aparecer;
  animation-duration: 0.5s;
  height: 640px;
}

.tarjeta {
  background: white;
  position: relative;
  box-shadow: 4px 4px 13px #aaa;
  border-radius: 10px;
  padding: 30px;
  padding-top: 0;
  margin-bottom: 25px;
  overflow: hidden;
}
.tarjeta h1 {
  color: var(--color);
  font-weight: 400;
  padding-bottom: 10px;
  padding-right: 40px;
  border-bottom: solid 1px #ccc;
}

.tabla .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: var(--color-tonalidad-principal1);
}
.tabla .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--color-success);
}
tarjeta-info {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

/*calendario*/
.calendario .fc h2.fc-toolbar-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  background: var(--color-gris4);
  width: 92px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  border-radius: 4px;
}
.calendario thead[role="presentation"] {
  display: none;
}
.calendario .fc-daygrid-day-events {
  position: absolute !important;
  bottom: 2px;
  left: 0;
  right: 0;
  height: 7px;
  min-height: 7px !important;
}
.calendario .fc-daygrid-day-events .fc-daygrid-event-harness,
.calendario .fc-daygrid-day-events .fc-daygrid-event-harness a {
  height: 7px;
  margin: auto;
  width: 7px;
}
.calendario .fc div.fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px;
}
.calendario .fc .fc-daygrid-day-top {
  text-align: center;
  display: block;
}
.calendario .fc-theme-standard td,
.calendario .fc .fc-scrollgrid-liquid {
  border: none;
}
.calendario .fc .fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.calendario .fc-theme-standard td {
  border-radius: 4px;
  font-size: 14px;
}

.calendario .fc-icon-chevron-left:before,
.calendario .fc-icon-chevron-right:before {
  content: "";
  border-right: solid 1px;
  width: 12px;
  height: 12px;
  display: block;
  border-top: solid 1px;
  margin-top: 6px;
}
.calendario .fc .fc-button-primary,
.calendario .fc .fc-button-primary:hover,
.calendario .fc .fc-button-primary:active {
  border: none !important;
  background: transparent !important;
  color: var(--color-gris1) !important;
}
.calendario .fc .fc-button-primary:focus {
  box-shadow: none !important;
}
.calendario .fc-icon-chevron-right:before {
  transform: rotate(45deg);
}

.calendario .fc-icon-chevron-left:before {
  transform: rotate(225deg);
  margin-left: 8px;
}
.calendario .fc .fc-bg-event {
  opacity: 1;
  border-radius: 4px;
}
.calendario .fc .fc-daygrid-day-frame:hover {
  background: var(--color-gris3);
  border-radius: 6px;
}
.calendario .fc .fc-daygrid-day-frame {
  cursor: pointer;
}
.tooltip {
  font-size: 9pt;
}

app-nuevo-proyecto .ng-select.ng-select-single .ng-select-container {
  height: 52px !important;
  border-color: var(--color-gris3);
}

.arrow_box_margen {
  padding: 15px;
}

mat-dialog-container .ng-dropdown-panel .scroll-host {
  max-height: 200px;
}
.mat-calendar-body-selected {
  background-color: #269171 !important;
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgba(29, 203, 152, 0.3) !important;
}
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: rgba(29, 203, 152, 0.3) !important;
}
.mat-calendar-body-in-range::before{
  background-color: rgba(183, 237, 221, 0.4);
}
.cotizadorSelector .ng-select-container{
  min-height: 37px !important;
}
.cotizadorSelector  .ng-select-container .ng-value-container .ng-placeholder{
  color: #2F3835 !important;
}
@media only screen and (max-width: 500px) {
  :root {
    --margen-contenedor: 16px;
  }
  app-header-bar {
    height: 76px;
  }
}
.redondo.ng-select .ng-select-container {
  border-radius: 50px !important;
  height: 30px !important;
}


.labelSimulador {
  font-size: 9pt;
  font-weight: 600;
  transform: translate(0px, 2px);
}

.yaxisSimulador{
  font-size: 9pt;
}

.btn{
  color: white;
  background: var(--color-tonalidad-principal1);
  border-radius: 4px;
  border:none;
  font-weight: 600;
}

